body {
  margin: 0;
  padding: 0;
  font-family: Manrope;
}
* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}
a {
  text-decoration: none;
  display: block;
}
ul {
  margin: 0;
  margin-inline: unset !important;
  padding: 0;
  list-style: none;
}
input {
  background-color: transparent;
  width: 100%;
}
input:focus {
  outline: none;
}
.tab-panel--selected {
  animation-name: tabPanel;
  animation-duration: 300ms;
  animation-timing-function: linear;
}
@keyframes tabPanel {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.alice-carousel__dots-item {
  cursor: pointer;
}


.cost-breakup {
  margin: 20px;
  margin-left: 100px;
  margin-right: 100px;
}

.Cost-breakup-detailed {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.cost-breakup h1 {
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 20px;
  margin-left: 5em;
}


.cost-table {
  width: 420px;
  border-collapse: collapse;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff3e6;
  font-size: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.input-container{
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

@media (max-width: 600px) {
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
@media (max-width: 450px) {
  .cost-table {
    width: 320px;
    border-collapse: collapse;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff3e6;
    font-size: 18px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .hero-img{
    display: none;
  }

}

@media (max-width:755) {

  .piechart{
    height: 90px;
  }
}


.cost-table th,
.cost-table td {
  border: 1px solid #ffcc99;
  padding: 8px;
  text-align: left;
}

.cost-table th {
  background-color: rgb(255 224 206 );
  color: #333;
}

.total-row {
  background-color: rgb(255 224 206); /* Darker orange */
  font-weight: bold;
  height: 20px;
}

.total-row td {
  text-align: left;
}

.cost-table th:first-child,
.cost-table td:first-child {
  width: 30%;
}

.cost-table th:nth-child(2),
.cost-table td:nth-child(2) {
  width: 50%;
}

.cost-table th:last-child,
.cost-table td:last-child {
  width: 20%;
}



.distribution-container_G_1{
  display: flex;
  color: white;
  font-size: 18px;
}
.distribution-left_G_1{
  display: flex;
  flex-direction: column;
  gap:35px;
  margin-top: 25px;
  width: 440px;
}
.distribution-right_G_1{
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 0px;
  margin-bottom: 40px;
  margin-left: 20px;
  
}
.distribution-mid_G_1{
  width: 130%;

}
.Fault{
  margin-bottom: 70px;
}
@media (max-width: 1200px) {
  .distribution-container_G_1{
    display: flex;
    justify-content: center;
    color: white;
    font-size: 13px;
  }
  .distribution-left_G_1{
    display: flex;
    flex-direction: column;
    gap:30px;
    margin-top: 0px;
    
  }
  .distribution-right_G_1{
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 500px;
  }
  .distribution-mid_G_1{
    width: 330%;
  
  }
  .Fault{
    margin-bottom: 30px;
  }
}
@media (max-width: 960px) {
  .distribution-container_G_1{
    display: flex;
    justify-content: center;
    color: white;
    font-size: 11px;
  }
  .distribution-left_G_1{
    display: flex;
    flex-direction: column;
    gap:30px;
    margin-top: 0px;
    
  }
  .distribution-right_G_1{
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 500px;
  }
  .distribution-mid_G_1{
    width: 430%;
  
  }
  .Fault{
    margin-bottom: 30px;
  }
}
@media (max-width: 840px) {
  .distribution-container_G_1{
    display: flex;
    justify-content: center;
    color: white;
    font-size: 9px;
  }
  .distribution-left_G_1{
    display: flex;
    flex-direction: column;
    gap:30px;
    margin-top: 0px;
    
  }
  .distribution-right_G_1{
    display: flex;
    flex-direction: column;
    gap: 45px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 450px;
  }
  .distribution-mid_G_1{
    width: 530%;
  
  }
  .Fault{
    margin-bottom: 30px;
  }
}
@media (max-width: 740px) {
  .distribution-container_G_1{
    display: flex;
    justify-content: center;
    color: white;
    font-size: 7px;
  }
  .distribution-left_G_1{
    display: flex;
    flex-direction: column;
    gap:42px;
    margin-top: 0px;
    
  }
  .distribution-right_G_1{
    display: flex;
    flex-direction: column;
    gap: 45px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 400px;
  }
  .distribution-mid_G_1{
    width: 530%;
  
  }
  .Fault{
    margin-bottom: 30px;
  }
}
@media (max-width: 640px) {
  .distribution-container_G_1{
    display: flex;
    justify-content: center;
    color: white;
    font-size: 7px;
  }
  .distribution-left_G_1{
    display: flex;
    flex-direction: column;
    gap:25px;
    margin-top: 0px;
    
  }
  .distribution-right_G_1{
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 400px;
  }
  .distribution-mid_G_1{
    width: 530%;
  
  }
  .Fault{
    margin-bottom: 20px;
  }
}
@media (max-width: 560px) {
  .distribution-container_G_1{
    display: flex;
    justify-content: center;
    color: white;
    font-size: 7px;
  }
  .distribution-left_G_1{
    display: flex;
    flex-direction: column;
    gap:20px;
    margin-top: 0px;
    
  }
  .distribution-right_G_1{
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 350px;
  }
  .distribution-mid_G_1{
    width: 530%;
  
  }
  .Fault{
    margin-bottom: 0px;
  }
}
@media (max-width: 500px) {
  .distribution-container_G_1{
    display: flex;
    justify-content: center;
    color: white;
    font-size: 6px;
  }
  .distribution-left_G_1{
    display: flex;
    flex-direction: column;
    gap:20px;
    margin-top: 0px;
    
  }
  .distribution-right_G_1{
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 350px;
  }
  .distribution-mid_G_1{
    width: 530%;
  
  }
  .Fault{
    margin-bottom: 0px;
  }
}
@media (max-width: 460px) {
  .distribution-container_G_1{
    display: flex;
    justify-content: center;
    color: white;
    font-size: 6px;
  }
  .distribution-left_G_1{
    display: flex;
    flex-direction: column;
    gap:15px;
    margin-top: 0px;
    
  }
  .distribution-right_G_1{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 300px;
  }
  .distribution-mid_G_1{
    width: 530%;
  
  }
  .Fault{
    margin-bottom: 0px;
  }
}
@media (max-width: 430px) {
  .distribution-container_G_1{
    display: flex;
    justify-content: center;
    color: white;
    font-size: 6px;
  }
  .distribution-left_G_1{
    display: flex;
    flex-direction: column;
    gap:15px;
    margin-top: 0px;
    
  }
  .distribution-right_G_1{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 300px;
  }
  .distribution-mid_G_1{
    width: 530%;
  
  }
  .Fault{
    margin-bottom: 0px;
  }
}
@media (max-width: 400px) {
  .distribution-container_G_1{
    display: flex;
    justify-content: center;
    color: white;
    font-size: 6px;
  }
  .distribution-left_G_1{
    display: flex;
    flex-direction: column;
    gap:10px;
    margin-top: 0px;
    
  }
  .distribution-right_G_1{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 2px;
    
  }
  .distribution-mid_G_1{
    width: 530%;
  
  }
  .Fault{
    margin-bottom: 0px;
  }
}



.line{
  width: 80%;
  height: 1px;
  background-color: #3b3b3b;
  margin-top: 20px;
  margin-bottom: 20px;
  
}
.distribution-container_G_2{
  display: flex;
  color: white;
  font-size: 18px;
}
.distribution-left_G_2{
  display: flex;
  flex-direction: column;
  gap:40px;
  margin-top: 25px;
  width: 440px;
}
.distribution-right_G_2{
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 60px;
  margin-bottom: 40px;
  margin-left: 20px;
  
}
.distribution-mid_G_2{
  width: 70%;

}
.Fault_2{
  margin-top: 50px;
}
@media (max-width: 1100px) {
  .distribution-container_G_2{
    display: flex;
    color: white;
    font-size: 14px;
  }
  .distribution-left_G_2{
    display: flex;
    flex-direction: column;
    gap:65px;
    margin-top: 15px;
    
  }
  .distribution-right_G_2{
    display: flex;
    flex-direction: column;
    gap: 45px;
    margin-top: 35px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 450px;
  }
  .distribution-mid_G_2{
    width: 350%;
  
  }
  .Fault_2{
    margin-top: 55px;
  }
}
@media (max-width: 1000px) {
  .distribution-container_G_2{
    display: flex;
    color: white;
    font-size: 13px;
  }
  .distribution-left_G_2{
    display: flex;
    flex-direction: column;
    gap:60px;
    margin-top: 15px;
    
  }
  .distribution-right_G_2{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 35px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 450px;
  }
  .distribution-mid_G_2{
    width: 350%;
  
  }
  .Fault_2{
    margin-top: 48px;
  }
}
@media (max-width: 900px) {
  .distribution-container_G_2{
    display: flex;
    color: white;
    font-size: 12px;
  }
  .distribution-left_G_2{
    display: flex;
    flex-direction: column;
    gap:45px;
    margin-top: 15px;
    
  }
  .distribution-right_G_2{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 35px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 450px;
  }
  .distribution-mid_G_2{
    width: 410%;
  
  }
  .Fault_2{
    margin-top: 48px;
  }
}
@media (max-width: 770px) {
  .distribution-container_G_2{
    display: flex;
    color: white;
    font-size: 10px;
  }
  .distribution-left_G_2{
    display: flex;
    flex-direction: column;
    gap:45px;
    margin-top: 15px;
    
  }
  .distribution-right_G_2{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 35px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 450px;
  }
  .distribution-mid_G_2{
    width: 410%;
  
  }
  .Fault_2{
    margin-top: 45px;
  }
}
@media (max-width: 670px) {
  .distribution-container_G_2{
    display: flex;
    color: white;
    font-size: 8px;
  }
  .distribution-left_G_2{
    display: flex;
    flex-direction: column;
    gap:35px;
    margin-top: 15px;
    
  }
  .distribution-right_G_2{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 35px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 400px;
  }
  .distribution-mid_G_2{
    width: 510%;
  
  }
  .Fault_2{
    margin-top: 45px;
  }
}
@media (max-width: 570px) {
  .distribution-container_G_2{
    display: flex;
    color: white;
    font-size: 7px;
  }
  .distribution-left_G_2{
    display: flex;
    flex-direction: column;
    gap:35px;
    margin-top: 15px;
    
  }
  .distribution-right_G_2{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 25px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 300px;
  }
  .distribution-mid_G_2{
    width: 510%;
  
  }
  .Fault_2{
    margin-top: 40px;
  }
}
@media (max-width: 500px) {
  .distribution-container_G_2{
    display: flex;
    color: white;
    font-size: 5px;
  }
  .distribution-left_G_2{
    display: flex;
    flex-direction: column;
    gap:30px;
    margin-top: 15px;
    
  }
  .distribution-right_G_2{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 300px;
  }
  .distribution-mid_G_2{
    width: 510%;
  
  }
  .Fault_2{
    margin-top: 40px;
  }
}
@media (max-width: 440px) {
  .distribution-container_G_2{
    display: flex;
    color: white;
    font-size: 5px;
  }
  .distribution-left_G_2{
    display: flex;
    flex-direction: column;
    gap:20px;
    margin-top: 15px;
    
  }
  .distribution-right_G_2{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 300px;
  }
  .distribution-mid_G_2{
    width: 510%;
  
  }
  .Fault_2{
    margin-top: 40px;
  }
}

@media (max-width: 400px) {
  .distribution-container_G_2{
    display: flex;
    color: white;
    font-size: 5px;
  }
  .distribution-left_G_2{
    display: flex;
    flex-direction: column;
    gap:10px;
    margin-top: 15px;
    
  }
  .distribution-right_G_2{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 320px;
  }
  .distribution-mid_G_2{
    width: 510%;
  
  }
  .Fault_2{
    margin-top: 40px;
  }
}

.distribution-container_G_3{
  display: flex;
  color: white;
  font-size: 18px;
}
.distribution-left_G_3{
  display: flex;
  flex-direction: column;
  gap:60px;
  margin-top: 70px;
  width: 440px;
}
.distribution-right_G_3{
  display: flex;
  flex-direction: column;
  gap: 70px;
  margin-top: 20px;
  margin-bottom: 40px;
  margin-left: 20px;
  
} 
.distribution-mid_G_3{
  width: 70%;

}
.Fault_3{
  margin-bottom: 80px;
}
@media (max-width: 1100px) {
  .distribution-container_G_3{
    display: flex;
    color: white;
    font-size: 14px;
  }
  .distribution-left_G_3{
    display: flex;
    flex-direction: column;
    gap:70px;
    margin-top: 90px;
    
  }
  .distribution-right_G_3{
    display: flex;
    flex-direction: column;
    
    gap: 90px;
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 400px;
    
  } 
  .distribution-mid_G_3{
    width: 350%;
  
  }
  .Fault_3{
    margin-bottom: 100px;
  }
}
@media (max-width: 1000px) {
  .distribution-container_G_3{
    display: flex;
    color: white;
    font-size: 14px;
  }
  .distribution-left_G_3{
    display: flex;
    flex-direction: column;
    gap:70px;
    margin-top: 50px;
    
  }
  .distribution-right_G_3{
    display: flex;
    flex-direction: column;
    
    gap: 90px;
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 400px;
    
  } 
  .distribution-mid_G_3{
    width: 350%;
  
  }
  .Fault_3{
    margin-bottom: 50px;
  }
}
@media (max-width: 900px) {
  .distribution-container_G_3{
    display: flex;
    color: white;
    font-size: 12px;
  }
  .distribution-left_G_3{
    display: flex;
    flex-direction: column;
    gap:50px;
    margin-top: 50px;
    
  }
  .distribution-right_G_3{
    display: flex;
    flex-direction: column;
    
    gap: 70px;
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 400px;
    
  } 
  .distribution-mid_G_3{
    width: 350%;
  
  }
  .Fault_3{
    margin-bottom: 50px;
  }
}
@media (max-width: 800px) {
  .distribution-container_G_3{
    display: flex;
    color: white;
    font-size: 12px;
  }
  .distribution-left_G_3{
    display: flex;
    flex-direction: column;
    gap:35px;
    margin-top: 30px;
    
  }
  .distribution-right_G_3{
    display: flex;
    flex-direction: column;
    
    gap: 40px;
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 300px;
    
  } 
  .distribution-mid_G_3{
    width: 350%;
  
  }
  .Fault_3{
    margin-bottom: 30px;
  }
}
@media (max-width: 700px) {
  .distribution-container_G_3{
    display: flex;
    color: white;
    font-size: 10px;
  }
  .distribution-left_G_3{
    display: flex;
    flex-direction: column;
    gap:35px;
    margin-top: 30px;
    
  }
  .distribution-right_G_3{
    display: flex;
    flex-direction: column;
    
    gap: 30px;
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 300px;
    
  } 
  .distribution-mid_G_3{
    width: 400%;
  
  }
  .Fault_3{
    margin-bottom: 30px;
  }
}
@media (max-width: 600px) {
  .distribution-container_G_3{
    display: flex;
    color: white;
    font-size: 9px;
  }
  .distribution-left_G_3{
    display: flex;
    flex-direction: column;
    gap:25px;
    margin-top: 30px;
    
  }
  .distribution-right_G_3{
    display: flex;
    flex-direction: column;
    
    gap: 25px;
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 300px;
    
  } 
  .distribution-mid_G_3{
    width: 450%;
  
  }
  .Fault_3{
    margin-bottom: 20px;
  }
}
@media (max-width: 540px) {
  .distribution-container_G_3{
    display: flex;
    color: white;
    font-size: 7px;
  }
  .distribution-left_G_3{
    display: flex;
    flex-direction: column;
    gap:25px;
    margin-top: 30px;
    
  }
  .distribution-right_G_3{
    display: flex;
    flex-direction: column;
    
    gap: 25px;
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 300px;
    
  } 
  .distribution-mid_G_3{
    width: 450%;
  
  }
  .Fault_3{
    margin-bottom: 20px;
  }
}
@media (max-width: 440px) {
  .distribution-container_G_3{
    display: flex;
    color: white;
    font-size: 6px;
  }
  .distribution-left_G_3{
    display: flex;
    flex-direction: column;
    gap:25px;
    margin-top: 30px;
    
  }
  .distribution-right_G_3{
    display: flex;
    flex-direction: column;
    
    gap: 25px;
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 300px;
    
  } 
  .distribution-mid_G_3{
    width: 450%;
  
  }
  .Fault_3{
    margin-bottom: 20px;
  }
}
@media (max-width: 400px) {
  .distribution-container_G_3{
    display: flex;
    color: white;
    font-size: 5px;
  }
  .distribution-left_G_3{
    display: flex;
    flex-direction: column;
    gap:25px;
    margin-top: 30px;
    
  }
  .distribution-right_G_3{
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 2px;
    width: 300px;
    
  } 
  .distribution-mid_G_3{
    width: 510%;
  
  }
  .Fault_3{
    margin-bottom: 20px;
  }
}


.distribution-container_G{
  display: flex;
  color: white;
  font-size: 11px;
}
.distribution-left_G{
  display: flex;
  flex-direction: column;
  gap:35px;
  margin-top: 50px;
  
}
.distribution-right_G{
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top:35px;
  margin-bottom: 40px;
  margin-left: 2px;
  
}
.distribution-mid_G{
  width: 130%;

}
.Fault_0{
  margin-top: 30px;
}

@media (max-width: 1200px) {
  .distribution-container_G{
    display: flex;
    color: white;
    font-size: 9px;
  }
  .distribution-left_G{
    display: flex;
    flex-direction: column;
    gap:40px;
    margin-top: 50px;
    
  }
  .distribution-right_G{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top:35px;
    margin-bottom: 40px;
    margin-left: 2px;
    
  }
  .distribution-mid_G{
    width: 130%;
  
  }
  .Fault_0{
    margin-top: 35px;
  }
}
@media (max-width: 1100px) {
  .distribution-container_G{
    display: flex;
    color: white;
    font-size: 9px;
  }
  .distribution-left_G{
    display: flex;
    flex-direction: column;
    gap:20px;
    margin-top: 50px;
    
  }
  .distribution-right_G{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top:30px;
    margin-bottom: 40px;
    margin-left: 2px;
    
  }
  .distribution-mid_G{
    width: 130%;
  
  }
  .Fault_0{
    margin-top: 35px;
  }
}
@media (max-width: 1000px) {
  .distribution-container_G{
    display: flex;
    color: white;
    font-size: 9px;
  }
  .distribution-left_G{
    display: flex;
    flex-direction: column;
    gap:15px;
    margin-top: 40px;
    
  }
  .distribution-right_G{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top:30px;
    margin-bottom: 40px;
    margin-left: 2px;
    
  }
  .distribution-mid_G{
    width: 130%;
  
  }
  .Fault_0{
    margin-top: 35px;
  }
}
@media (max-width: 900px) {
  .distribution-container_G{
    display: flex;
    color: white;
    font-size: 9px;
  }
  .distribution-left_G{
    display: flex;
    flex-direction: column;
    gap:15px;
    margin-top: 20px;
    
  }
  .distribution-right_G{
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-top:20px;
    margin-bottom: 40px;
    margin-left: 2px;
    
  }
  .distribution-mid_G{
    width: 130%;
  
  }
  .Fault_0{
    margin-top: 25px;
  }
}
@media (max-width: 750px) {
  .distribution-container_G{
    display: flex;
    color: white;
    font-size: 7px;
  }
  .distribution-left_G{
    display: flex;
    flex-direction: column;
    gap:15px;
    margin-top: 20px;
    
  }
  .distribution-right_G{
    display: flex;
    flex-direction: column;
    gap: 9px;
    margin-top:20px;
    margin-bottom: 40px;
    margin-left: 2px;
    
  }
  .distribution-mid_G{
    width: 130%;
  
  }
  .Fault_0{
    margin-top: 25px;
  }
}
@media (max-width: 650px) {
  .distribution-container_G{
    display: flex;
    color: white;
    font-size: 6px;
  }
  .distribution-left_G{
    display: flex;
    flex-direction: column;
    gap:12px;
    margin-top: 20px;
    
  }
  .distribution-right_G{
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-top:18px;
    margin-bottom: 40px;
    margin-left: 2px;
    
  }
  .distribution-mid_G{
    width: 130%;
  
  }
  .Fault_0{
    margin-top: 20px;
  }
}
@media (max-width: 560px) {
  .distribution-container_G{
    display: flex;
    color: white;
    font-size: 6px;
  }
  .distribution-left_G{
    display: flex;
    flex-direction: column;
    gap:0px;
    margin-top: 20px;
    
  }
  .distribution-right_G{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top:8px;
    margin-bottom: 40px;
    margin-left: 2px;
    
  }
  .distribution-mid_G{
    width: 130%;
  
  }
  .Fault_0{
    margin-top: 20px;
  }
}
@media (max-width: 500px) {
  .distribution-container_G{
    display: flex;
    color: white;
    font-size: 4px;
  }
  .distribution-left_G{
    display: flex;
    flex-direction: column;
    gap:10px;
    margin-top: 20px;
    
  }
  .distribution-right_G{
    display: flex;
    flex-direction: column;
    gap: 13px;
    margin-top:8px;
    margin-bottom: 40px;
    margin-left: 2px;
    
  }
  .distribution-mid_G{
    width: 130%;
  
  }
  .Fault_0{
    margin-top: 20px;
  }
}
@media (max-width: 450px) {
  .distribution-container_G{
    display: flex;
    color: white;
    font-size: 3px;
  }
  .distribution-left_G{
    display: flex;
    flex-direction: column;
    gap:8px;
    margin-top: 15px;
    
  }
  .distribution-right_G{
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top:8px;
    margin-bottom: 40px;
    margin-left: 2px;
    
  }
  .distribution-mid_G{
    width: 130%;
  
  }
  .Fault_0{
    margin-top: 10px;
  }
} 
@media (max-width: 400px) {
  .distribution-container_G{
    display: flex;
    color: white;
    font-size: 3px;
  }
  .distribution-left_G{
    display: flex;
    flex-direction: column;
    gap:8px;
    margin-top: 15px;
    
  }
  .distribution-right_G{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top:8px;
    margin-bottom: 40px;
    margin-left: 2px;
    
  }
  .distribution-mid_G{
    width: 130%;
  
  }
  .Fault_0{
    margin-top: 10px;
  }
}

